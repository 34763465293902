html, body {
	margin:0px;
	height:100%;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	//@include breakpoint-label;
	background: #fff;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; }
	&:focus,
	&:active { color: $color-link-active; }
}

h1, h2, h3, h4, h5, h6, a, p, span {
	-webkit-font-smoothing: antialiased;
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: $color-selection;
	text-shadow: none;
	color:white;
}

::selection {
	background: $color-selection;
	text-shadow: none;
	color:white;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $light-grey;
	opacity: 0;
	font-family:$abel;
	text-transform:uppercase;
	font-size:15px;
	line-height:22px;
	transition:all 300ms ease-in-out;
	transition-delay:300ms;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $light-grey;
	opacity: 0;
	font-family:$abel;
	text-transform:uppercase;
	font-size:15px;
	line-height:22px;
	transition:all 300ms ease-in-out;
	transition-delay:600ms;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $light-grey;
	opacity: 0;
	font-family:$abel;
	text-transform:uppercase;
	font-size:15px;
	line-height:22px;
	transition:all 300ms ease-in-out;
	transition-delay:600ms;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $light-grey;
	opacity: 0;
	font-family:$abel;
	text-transform:uppercase;
	font-size:15px;
	line-height:22px;
	transition:all 300ms ease-in-out;
	transition-delay:600ms;
}
