/* =======================================================================
c-banner
========================================================================== */

.c-banner__bottom {
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.c-banner__copyright {
	width:45%;
	box-sizing:border-box;
	padding:30px 40px;
	font-family:$futura;
	font-size:12px;
	color:rgba($black-opacity, 0.5);
	text-transform:uppercase;
	letter-spacing:1px;
	font-weight:900;
	transform:translateY(100%);
	transition:all 500ms ease-in-out;

	@include mq('medium') {
		display:none;
	}

}

.c-banner__links {
	width:55%;
	box-sizing:border-box;
	padding:30px;
	text-align:right;
	transform:translateY(100%);
	transition:all 500ms ease-in-out;

	@include mq('medium') {
		width:100%;
		text-align:left;
	}

	a {
		font-family:$futura;
		font-size:10px;
		color:rgba($black-opacity, 0.5);
		text-transform:uppercase;
		letter-spacing:1px;
		font-weight:900;
		padding-left:20px;

		@include mq('medium') {
			padding-left:0px;
			padding-right:5px;
		}

		span {
			color:$blue;
		}

		&:last-child {
			color:$red;

			@include mq('medium') {
				color:white;
			}
		}
	}
}