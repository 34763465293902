.c-jobs {
	flex:auto;

	@include mq('large') {
		width:100%;
	}

	ul {
		list-style-type: none;
		padding-left:0px;
		position:relative;
		z-index: 9999;

		li {
			display:flex;
			flex-flow:row nowrap;
			justify-content: space-between;
			align-items: center;

			@include mq('large') {
				flex-flow:column wrap;
				align-items:flex-start;
			}

			&.label {
				padding:30px 10px;
				border-bottom:3px solid $blue;

				@include mq('large') {
					display:none;
				}


				span {
					font-family: $abel;
					font-size:18px;
					line-height: 24px;
					text-transform: uppercase;
					color:$blue;
					box-sizing:border-box;

					&:nth-child(1) {
						width:50%;
					}

					&:nth-child(2) {
						width:25%;
						padding-left:10px;
						box-sizing:border-box;
					}

					&:nth-child(3) {
						width:25%;
						text-align:center;
					}

					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {

						@include mq('large') {
							width:100%;
						}
					}
				}
			}

			&.c-jobs__entry {
				border-bottom:1px solid $border-grey;
				padding:6px 0px;
				position: relative;
				transition: all 300ms ease-in-out;

				@include mq('large') {
					padding:30px 0px;
				}

				&:last-child {
					border-bottom:0px;
				}

				&:before {
					content:'';
					position:absolute;
					top:3px;
					left:0;
					right:0;
					height:1px;
					background:$jobs-border-grey;
					display: block;
					opacity: 0;
					transition:all 300ms ease-in-out;

					@include mq('large') {
						display:none;
					}
				}

				&:after {
					content:'';
					position:absolute;
					bottom:3px;
					left:0;
					right:0;
					height:1px;
					background:$jobs-border-grey;
					display: block;
					opacity: 0;
					transition:all 300ms ease-in-out;

					@include mq('large') {
						display:none;
					}
				}

				&:hover {
					background:#efeff0;

					@include mq('large') {
						background:transparent;
					}

					a {
						background:$red;
						color:white;

						img {
							filter:brightness(0) invert(1);
						}
					}

					&:before {
						opacity: 1;
					}

					&:after {
						opacity: 1;
					}

					h3 {
						color:$red;

						@include mq('large') {
							color:$blue;
						}
					}
				}

				* {
					width:calc(100% / 3);
					box-sizing:border-box;
				}

				h3 {
					font-family: $futura;
					font-weight: 700;
					font-size: 14px;
					line-height: 20px;
					text-transform: uppercase;
					color:$blue;
					letter-spacing: 1px;
					width:50%;
					margin:0px;
					padding:30px 10px;
					transition:all 300ms ease-in-out;

					@include mq('large') {
						width:100%;

						&:before {
							content: attr(data-label);
							font-family:$abel;
							font-size:15px;
							font-weight: 400;
							color:$blue;
							text-transform: uppercase;
							display: block;
							margin-bottom: 5px;
						}
					}
				}

				span {
					font-family: $futura;
					font-weight: 700;
					font-size: 14px;
					line-height: 20px;
					text-transform: uppercase;
					color:$blue;
					letter-spacing: 1px;
					width:25%;					
					padding:30px 10px;

					@include mq('large') {
						padding: 0px 10px 30px;
					

						&:before {
							content: attr(data-label);
							font-family:$abel;
							font-size:15px;
							font-weight: 400;
							color:$blue;
							text-transform: uppercase;
							display: block;
						    margin-bottom: 5px;
						}
					}

				}

				a {
					font-family: $futura;
					font-weight: 700;
					font-size: 13px;
					line-height: 28px;
					text-transform: uppercase;
					color:$jobs-link-grey;
					letter-spacing: 1px;
					display:inline-flex;
					flex-flow:row wrap;
					justify-content: center;
					align-items:center;
					width:25%;
					padding:30px 30px;
					transition:all 300ms ease-in-out;

					@include mq('large') {
						width:100%;
						background:$red;
						color:white;
						padding:12px 25px;
						max-width: 250px;
					}


					img {
						max-width:8px;
						backface-visibility: hidden;
						margin-left:10px;
						transition:all 300ms ease-in-out;

						@include mq('large') {
							filter:brightness(0) invert(1);
						}
					}
				}
			}
		}
	}
}