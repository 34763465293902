.c-form {
	padding: 40px 0px;
	max-width:1000px;
	margin:0 auto;
	box-sizing: border-box;

	.mm-form__item {
		position:relative;
		margin-bottom:40px;

		.mm-form__input {
			position:relative;
			overflow:hidden;

			&:before {
				content:'';
				position:absolute;
				bottom:0px;
				left:0px;
				right:0px;
				height:1px;
				background:white;
				z-index: 9999;
				transition:all 500ms ease-in-out;
				transform:translateX(-100%);
			}
		}

		&.active {

			.mm-form__placeholder {
				transform:translateY(-25px);
				transition-delay: 0ms;
				color:$red;
			}

			.mm-form__input:before {
				transform:translateX(0%);
			}
		}

		span.wpcf7-not-valid-tip {
			font-family: $abel;
			font-size: 13px;
			color: $red;
			position: absolute;
			bottom: -35px;
		}
	}

	div.wpcf7-validation-errors,
	div.wpcf7-mail-sent-ng,
	div.wpcf7-mail-sent-ok {
		border:0px;
		margin:0px !important;
		padding:0px 20px !important;
		font-family: $abel;
		font-size:15px;
		line-height: 22px;
		box-sizing:border-box;
	    -webkit-font-smoothing: antialiased;
	}

	div.wpcf7-validation-errors,
	div.wpcf7-mail-sent-ng {
		color:$red;
	}

	div.wpcf7-mail-sent-ok {
		color:$green;
	}

	.mm-form__placeholder {
		position:absolute;
	    top: 4px;
	    left: 20px;
	    font-family:$futura;
	    font-size:12px;
	    color:white;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    transition:all 300ms ease-in-out;
	    transition-delay: 500ms;
	    z-index: 0;
	    pointer-events:none;
	    -webkit-font-smoothing: antialiased;
	    font-weight:700;

	    @include mq('large') {
	    	left:0px;
	    }
	}

	.mm-form__row {
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items: flex-end;

		@include mq('large') {
			flex-flow:column wrap;
		}

		& > div {
			box-sizing:border-box;
			padding:0px 20px;

			@include mq('large') {
				padding:0px 0px;
			}

			input, textarea {
				width:100%;
				background:transparent;
				border-top:0px;
				border-right:0px;
				border-left:0px;
				border-bottom:2px solid #4c5158;
				padding:4px 0px;
				color: $light-grey;
				opacity: 1;
				font-family:$abel;
				text-transform:uppercase;
				font-size:15px;
				line-height:22px;

				&:focus {
					outline:0;

					&::-webkit-input-placeholder {
						opacity: 1 !important;

					}

					&:-moz-placeholder {
						opacity: 1 !important;

					}

					&::-moz-placeholder {
						opacity: 1 !important;

					}

					&:-ms-input-placeholder {
						opacity: 1 !important;

					}

				}

				&.wpcf7-submit {
					border-bottom:0px;
					max-width: 120px;
				    border: 1px solid #fff;
				    height: 40px;
				    font-family:$futura;
				    font-size:13px;
				    font-weight:700;
	  				-webkit-font-smoothing: antialiased;
	  				transition:all 300ms ease-in-out;
	  				cursor: pointer;

	  				&:hover {
	  					background:white;
	  					color:$blue;
	  				}

	  				& + .ajax-loader {
	  					display:none;
	  				}
				}
			}
		}

		&.one {
			
			& > div {
				width:33.3333%;

				@include mq('large') {
					width:100%;
				}
			}
		}

		&.two {

			& > div {
				margin-bottom:20px;
			}
			
			& > div:nth-child(1) {
				width:66.6666%;

				@include mq('large') {
					width:100%;
				}
			}

			& > div:nth-child(2) {
				width:33.3333%;

				@include mq('large') {
					width:100%;
				}
			}
		}
	}
	
}