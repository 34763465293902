.st1 {
	fill:$red;
}

svg {
	overflow:visible;
	transform:scale(1.2);

	@include mq('medium') {
		transform: scale(1);
	}

	@include mq('xsmall') {
		transform: scale(0.8);
	}
}

polygon.c-left {
	fill:url(#center-left);
}

polygon.c-right {
	fill:url(#center-right);
}

g {
	transition:all 500ms ease-in-out;
}

.svg-wrapper {
	width:100%;
	height:100%;
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
}


.home-animation {
	position:relative;
	height:100%;
	overflow:hidden;
	box-shadow: 0px 10px 150px rgba(0, 0, 0, 0.3);

	.svg-wrapper {

	}

	&:before {
		content:'';
		position:absolute;
		bottom:0;
		right:0;
		left:0;
		top:0;
		height:100%;
		background:#f84343;
		transform:translateY(100%);
	}

	&:after {
		content:'';
		position:absolute;
		bottom:0;
		left:0;
		top:0;
		width:45%;
		height:100%;
		background:#f84343;
		transform:translateX(-100%);

		@include mq('medium') {
        	width:100%;
    	}
	}

	#circle {
		transform: scale(0);
		transform-origin: center;
		transition-delay: 650ms;
		position:relative;

	}

	
	&.step-1 {

		#circle {
			transform: scale(1);
		}
	}


	&.step-2 {

		.svg-wrapper {
			animation-name:svgSquare;
			animation-duration:1750ms;
			animation-iteration-count: 1;
			transform:translateY(-100%);
		}

		&:before {
			animation-name:redSquare;
			animation-duration:1750ms;
			animation-iteration-count: 1;
			animation-timing-function: cubic-bezier(0.755, 0.070, 0.820, 0.215);
			animation-direction:alternate;
		}
	}

	&.step-3 {

		&:after {
			content:'';
			transform:translateX(0%);
			transition:all 1000ms ease-in-out;
		}

		.mm-quali-logo {
			transform:translateX(0%);
			transition:transform 1500ms ease-in-out;
			transition-delay:300ms;
		}
	}

	&.step-4 {
		h2 {
			
			span {
				transform: translateY(0%);
				opacity:1;
			}
		}
	}

	&.step-5 {
		.c-header__logo {
			transform:translateY(0%);
		}

		.c-header__menu,
		.c-mobile__icon {
			transform:translateY(0%);
			transition-delay: 300ms;
		}
	}

	&.step-6 {
		.c-banner__copyright {
			transform:translateY(0%);
			transition-delay: 300ms;
		}

		.c-banner__links {
			transform:translateY(0%);
		}
	}
}

.mm-quali-logo {
	display: inline-block;
    position: absolute;
    top: 0;
    left: 40%;
    bottom: 0;
    margin: auto 0;
    z-index: 999;
    max-width: initial;
    width: 120%;
    transform:translateX(100%);

    @include mq('medium') {
	    top: -60%;
	    left: 30px;
	    width: 900px;
    }
}

@keyframes redSquare {
	0% {
		transform:translateY(100%);
	}
	30% {
		transform:translateY(0%);
	}
	70% {
		transform:translateY(0%);
	}
	100% {
		transform:translateY(-100%);
	}
}

@keyframes svgSquare {
	0% {
		transform:translateY(0%);
	}
	60% {
		transform:translateY(0%);
	}
	100% {
		transform:translateY(-100%);
	}
}

.mm-banner h2 {
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 45%;
    padding: 30px 8% 30px 30px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;

    @include mq('medium') {
        width:100%;
		align-items:flex-start;
		justify-content: flex-end;
		padding:30px;
		margin-bottom:100px;
    }

    @include mq('xsmall') {
		margin-bottom:50px;
    }

    span {
    	font-family:$futura;
    	font-size:70px;
    	line-height:85px;
    	color:$blue;
    	text-transform:uppercase;
    	transform:translateY(200%);
    	opacity:0;
    	transition:all 500ms ease-in-out;

    	@include mq('large') {
    		font-size:50px;
    		line-height: 65px;
    	}

    	&:nth-child(1) {
    		transition-delay: 100ms;
    	}

    	&:nth-child(2) {
			transition-delay: 300ms;
    	}

    	&:last-child {
    		color:white;
    		transition-delay: 500ms;
    	}
    }
}