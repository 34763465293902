.c-clients {
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	flex:auto;

	img {
		max-width:150px;
		margin:20px 30px;

		&:first-child {
			max-width:175px;
		}

		&:last-child {
			max-width:125px;
		}
	}
}