.c-panes {
	position:absolute;
	top:140px;
	right:0px;
	left:0px;
	margin:auto;
	z-index: 9999;
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	transform:translateY(100%);
	opacity:0;
	transition:all 500ms ease-in-out;
	pointer-events:none;

	@include mq('large') {
		top:100px;
	}

	&.active {
		transform:translateY(0%);
		opacity:1;
		pointer-events:initial;
	}

	&__close {
		position:absolute;
		top:0px;
		right:0px;
		background:$red;
		width:50px;
		height:50px;
		display:flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items:center;
		cursor:pointer;
		transition:all 300ms ease-in-out;

		&:hover {
			background:lighten($red, 4%);
		}

		img {
			max-width:10px;
		}
	}

	&__wrapper {
		width:100%;
		height:525px;
		background-size:cover;
		background-position: top center;
		background-repeat:no-repeat;
		padding:0px 40px;
		display:flex;
		flex-flow:row wrap;
		justify-content: flex-start;
		align-items:flex-start;
		position:relative;

		@include mq('large') {
			flex-flow:column wrap;
			justify-content: flex-start;
			align-items: center;
		}

		@include mq('small') {
			height:220px;
			box-sizing:border-box;
			padding:0px 0px;
		}

		.c-panes__text {
			max-width: 530px;
			width: 100%;
			padding: 60px;
			background: white;
			box-shadow: 0 0 40px rgba(0,0,0,.2);
			box-sizing:border-box;

			@include mq('large') {
				margin-bottom:0px;
				margin-top:220px;
			}

			@include mq('small') {
				padding:60px 40px;
			}

		    h2 {
				font-family: $futura;
				font-size:60px;
				line-height:60px;
				font-weight: 700;
				text-transform:uppercase;
				color:$blue;

				&:after {
					content:'.';
					color:$red;
				}

				@include mq('xsmall') {
					font-size:35px;
					line-height: 35px;
				}
		    }

		    p {
				font-family: abel;
				font-size: 15px;
				line-height: 28px;
				color: #7f8288;
				letter-spacing: 1px;
				margin:0px;
		    }
		}

		.c-panes__next {
			display: inline-flex;
			flex-flow:row wrap;
			justify-content: space-between;
			align-items:center;
			padding: 16px 30px;
			background: white;
			color: #000;
			position: absolute;
			bottom: 0px;
			right: 0px;
			font-family:$futura;
			font-size:12px;
			line-height: 16px;
			color:$blue;
			font-weight:700;
			text-transform:uppercase;

			@include mq('large') {
				top:0px;
				left:0px;
				right:initial;
				bottom:initial;
			}

			img {
				max-width:8px;
				margin-left:10px;
			}
		}
	}
}