.c-footer {
	background-image:url('../images/footer-logo-bg.svg');
	background-repeat:no-repeat;
	background-position:top center;
	background-size:120%;
	position:relative;
	background-color:$blue;
	padding:100px 40px 40px;

	&__container {
		max-width:1000px;
		margin:0 auto;
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;

		@include mq('large') {
			flex-flow:column wrap;

		}
	}

	&__address {
		padding-left:40px;

		@include mq('large') {
			padding-left:0px;
		}

		& > span {
			font-family: $abel;
			font-size:15px;
			line-height: 22px;
			color:$red;
			margin-bottom: 20px;
			display:block;
		}

		& > div {
			font-family: $abel;
			font-size:14px;
			line-height: 22px;
			color:$light-grey;
			text-transform: uppercase;
			-webkit-font-smoothing: antialiased;
			margin-bottom:10px;

			a {
				font-family:$futura;
				color:white;
				font-weight:900;
			}

		}
	}

	&__copyright {
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items: center;
		font-family: $futura;
		font-size:12px;
		line-height: 16px;
		color:$light-grey;
		text-transform: uppercase;
		font-weight: 700;
		-webkit-font-smoothing: antialiased;

		.right {

			a {
				display:flex;
				flex-flow:row nowrap;
				justify-content: flex-end;
				align-items:center;
				margin-left:25px;
			}

			img {
				max-width: 25px;
				margin-left:10px;
				transition:all 300ms ease-in-out;
			}

			a:hover {

				img {
					filter:brightness(0%) invert(100%);
				}
			}
		}

		@include mq('small') {
			flex-flow:column wrap;
			align-items:flex-start;

			.left {
				margin-bottom:10px;
			}
		}

		a {
			color:$light-grey;
			margin-left:30px;
			font-size:12px;

			@include mq('small') {
				margin-left:0px;
				margin-right:30px;
			}
		}
	}


	h2 {
		font-family: $futura;
		font-weight:900;
		font-size: 90px;
		line-height: 90px;
		color:white;
		text-transform:uppercase;
		margin:0px;

		@include mq('large') {
			font-size:55px;
			line-height: 55px;
			margin-bottom:30px;
		}

		@include mq('xsmall') {
			font-size:33px;
			line-height: 40px;
			margin-bottom:30px;
		}

		&:after {
			content:'.';
			color:$red;
		}
	}

	h3 {
		font-family: $futura;
		font-weight:900;
		font-size: 14px;
		line-height: 20px;
		color:white;
		text-transform:uppercase;
		margin:0px 0px 5px;
		letter-spacing: 1px;
	}
}