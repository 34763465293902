.c-separator {
	text-align:center;
	position:absolute;
	left:0;
	right:0;
	bottom:-100px;
	transition:all 300ms ease-in-out;
	
	@include mq('xsmall') {
		bottom:0px;
	}


	img {
		max-width:60px;
		backface-visibility: hidden;
	}

	&:before {
		content:'';
		display: block;
		width:10px;
		height:10px;
		background:$red;
		border-radius:100%;
		position:absolute;
		left:0px;
		right:0px;
		top:-20px;
		margin:0 auto;
	}

	&:after {
		content:'';
		position:absolute;
		display:block;
		margin:0 auto;
		top:40px;
		left:0px;
		right:0px;
		height:30px;
		width:1px;
		background:$red;

	}
}

.wrapper--2 .c-separator {
	bottom:0px;
}