@keyframes sticky {
    0% {
        transform:translateY(-71px);
    }

    100% {
        transform:translateY(0);
    }
}

.c-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 55%;
    margin: 0 0 0 auto;
    position:relative;
    z-index: 9999;
    padding: 40px;
    box-sizing:border-box;
    position:absolute;
    top:0;
    right:0;
    width:100%;

    @include mq('medium') {
        max-width:100%;
        background:white;
        padding:20px 30px;
    }

    &.sticky {
        position: fixed;
        max-width: 100%;
        background: white;
        box-shadow: 0px 0px 50px rgba(0,0,0,0.3);
        z-index: 999999;
        animation: sticky 500ms ease-in-out;
        animation-direction:alternate;
        padding:20px 40px;

        .c-header__menu,
        .c-header__logo {
            transform:translateY(0%) !important;
        }
    }

    &__logo {
		transform:translateY(-300%);
		transition:all 500ms ease-in-out;

    	img {
    		max-width:200px;
    		width:100%;
    		margin-top:5px;

            @media screen and (max-width:1290px) {
                    max-width: 170px;
            }
    	}
    }

    &__menu {
		display: flex;
	    flex-flow: row wrap;
	    justify-content: flex-end;
	    align-items: center;
	    list-style-type: none;
	    padding:0px;
	    margin:0px;
	    transform:translateY(-300%);
	    transition:all 500ms ease-in-out;

        @media screen and (max-width:1150px) {
            display:none;
        }

	    li {
	    	padding-left:30px;

            @media screen and (max-width:1290px) {
                padding-left:15px;
            }

	    	a {
	    		font-family:$futura;
	    		font-weight: 700;
	    		font-size:12px;
	    		line-height: 16px;
	    		color:$blue;
	    		text-transform: uppercase;
                transition:color 300ms ease-in-out;

                &:hover {
                    color:$red;
                }

                @media screen and (max-width:1290px) {
                    font-size:11px;
                }
	    	}
	    }
    }
}