.c-temoignages {
	
	.js-temoignages {
		width:100%;
		margin-top: 40px;

		& > div {
			max-width: 550px;
			margin:0 auto;
			text-align:center;

			h3 {
				font-family:$futura;
				font-size: 20px;
				line-height: 26px;
				color:$blue;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin-bottom:15px;
			}

			h3 + span {
				font-family:$futura;
				font-size: 14px;
				line-height: 20px;
				color:$red;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 700;
				margin-bottom:20px;
				display: block;
			}

			p {
				font-family: abel;
				font-size: 15px;
				line-height: 24px;
				color: #7f8288;
				letter-spacing: 1px;
			}
		}

		.slick-dots {
			list-style-type:none;
			padding-left:0px;
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:center;

			li {
				margin:0px 2px;

				&.slick-active {

					button {
						background:$red;
					}
				}


				button {
					margin:0px 5px;
					display: inline-block;
					font-size:0;
					background:$border-grey;
					border-radius:100%;
					border:0px;
					width:15px;
					height:15px;
					transition:all 300ms ease-in-out;

					&:focus {
						outline: 0;
					}
				}
			}
		}
	}
}

.slick-slide {
	opacity:0;
	transition:all 500ms ease-in-out;
}

.slick-slide.slick-active {
	opacity:1;
}