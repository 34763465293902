@charset "UTF-8";
/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: 'abel';
  src: url("../fonts/abel/abel-regular.ttf") format("truetype");
}

/* =======================================================================
Variables
========================================================================== */
/* line 39, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
#breakpoint:before {
  display: block;
  content: "xlarge (default --> 1025px to 1199px)";
}

@media (max-width: 1024px) {
  /* line 45, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
  #breakpoint:before {
    content: "large --> max-width: 1024px";
  }
}

@media (max-width: 767px) {
  /* line 51, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
  #breakpoint:before {
    content: "medium --> max-width: 767px";
  }
}

@media (max-width: 599px) {
  /* line 57, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
  #breakpoint:before {
    content: "small --> max-width: 599px";
  }
}

@media (max-width: 479px) {
  /* line 63, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
  #breakpoint:before {
    content: "xsmall --> max-width: 479px";
  }
}

@media (min-width: 1200px) {
  /* line 69, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mediaquery.scss */
  #breakpoint:before {
    content: "xxl --> min-width: 1200px";
  }
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 7, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.clearfix:after {
  clear: both;
}

/* line 11, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.clearfix {
  *zoom: 1;
}

/* line 15, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 24, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 25, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_mixins.scss */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
html, body {
  margin: 0px;
  height: 100%;
}

/* line 6, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
html,
button,
input,
select,
textarea {
  color: #333;
  font-family: Arial, Verdana, sans-serif;
}

/* line 15, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
body {
  background: #fff;
  font-size: 16px;
  line-height: 1.4;
}

/* line 22, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
a {
  color: #ff0000;
  text-decoration: none;
}

/* line 25, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
a:hover {
  color: #990000;
}

/* line 26, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
a:focus, a:active {
  color: #330000;
}

/* line 30, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
h1, h2, h3, h4, h5, h6, a, p, span {
  -webkit-font-smoothing: antialiased;
}

/* line 34, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 36, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 45, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 50, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 55, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: #2269f6;
  text-shadow: none;
  color: white;
}

/* line 61, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
::selection {
  background: #2269f6;
  text-shadow: none;
  color: white;
}

/* line 68, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #f6f6f6;
  opacity: 0;
  font-family: "abel";
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  transition: all 300ms ease-in-out;
  transition-delay: 300ms;
}

/* line 78, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #f6f6f6;
  opacity: 0;
  font-family: "abel";
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  transition: all 300ms ease-in-out;
  transition-delay: 600ms;
}

/* line 88, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #f6f6f6;
  opacity: 0;
  font-family: "abel";
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  transition: all 300ms ease-in-out;
  transition-delay: 600ms;
}

/* line 98, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #f6f6f6;
  opacity: 0;
  font-family: "abel";
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  transition: all 300ms ease-in-out;
  transition-delay: 600ms;
}

/* =======================================================================
Footer
========================================================================== */
/* =======================================================================
Forms
========================================================================== */
/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/global/_layout.scss */
.l-6 {
  width: 50%;
  float: left;
}

/* =======================================================================
Navigation
========================================================================== */
/* =======================================================================
Components
========================================================================== */
/* =======================================================================
c-banner
========================================================================== */
/* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

/* line 17, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__copyright {
  width: 45%;
  box-sizing: border-box;
  padding: 30px 40px;
  font-family: "futura-pt-bold";
  font-size: 12px;
  color: rgba(15, 28, 45, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
}

@media (max-width: 767px) {
  /* line 17, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
  .c-banner__copyright {
    display: none;
  }
}

/* line 36, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__links {
  width: 55%;
  box-sizing: border-box;
  padding: 30px;
  text-align: right;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
}

@media (max-width: 767px) {
  /* line 36, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
  .c-banner__links {
    width: 100%;
    text-align: left;
  }
}

/* line 49, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__links a {
  font-family: "futura-pt-bold";
  font-size: 10px;
  color: rgba(15, 28, 45, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  padding-left: 20px;
}

@media (max-width: 767px) {
  /* line 49, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
  .c-banner__links a {
    padding-left: 0px;
    padding-right: 5px;
  }
}

/* line 63, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__links a span {
  color: #0F1C2E;
}

/* line 67, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
.c-banner__links a:last-child {
  color: #F94343;
}

@media (max-width: 767px) {
  /* line 67, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-banner.scss */
  .c-banner__links a:last-child {
    color: white;
  }
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.st1 {
  fill: #F94343;
}

/* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
svg {
  overflow: visible;
  transform: scale(1.2);
}

@media (max-width: 767px) {
  /* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  svg {
    transform: scale(1);
  }
}

@media (max-width: 479px) {
  /* line 5, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  svg {
    transform: scale(0.8);
  }
}

/* line 18, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
polygon.c-left {
  fill: url(#center-left);
}

/* line 22, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
polygon.c-right {
  fill: url(#center-right);
}

/* line 26, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
g {
  transition: all 500ms ease-in-out;
}

/* line 30, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.svg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/* line 40, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation {
  position: relative;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 10px 150px rgba(0, 0, 0, 0.3);
}

/* line 50, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  background: #f84343;
  transform: translateY(100%);
}

/* line 62, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 45%;
  height: 100%;
  background: #f84343;
  transform: translateX(-100%);
}

@media (max-width: 767px) {
  /* line 62, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  .home-animation:after {
    width: 100%;
  }
}

/* line 78, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation #circle {
  transform: scale(0);
  transform-origin: center;
  transition-delay: 650ms;
  position: relative;
}

/* line 89, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-1 #circle {
  transform: scale(1);
}

/* line 97, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-2 .svg-wrapper {
  animation-name: svgSquare;
  animation-duration: 1750ms;
  animation-iteration-count: 1;
  transform: translateY(-100%);
}

/* line 104, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-2:before {
  animation-name: redSquare;
  animation-duration: 1750ms;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.755, 0.07, 0.82, 0.215);
  animation-direction: alternate;
}

/* line 115, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-3:after {
  content: '';
  transform: translateX(0%);
  transition: all 1000ms ease-in-out;
}

/* line 121, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-3 .mm-quali-logo {
  transform: translateX(0%);
  transition: transform 1500ms ease-in-out;
  transition-delay: 300ms;
}

/* line 131, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-4 h2 span {
  transform: translateY(0%);
  opacity: 1;
}

/* line 139, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-5 .c-header__logo {
  transform: translateY(0%);
}

/* line 143, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-5 .c-header__menu,
.home-animation.step-5 .c-mobile__icon {
  transform: translateY(0%);
  transition-delay: 300ms;
}

/* line 151, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-6 .c-banner__copyright {
  transform: translateY(0%);
  transition-delay: 300ms;
}

/* line 156, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.home-animation.step-6 .c-banner__links {
  transform: translateY(0%);
}

/* line 162, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-quali-logo {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 40%;
  bottom: 0;
  margin: auto 0;
  z-index: 999;
  max-width: initial;
  width: 120%;
  transform: translateX(100%);
}

@media (max-width: 767px) {
  /* line 162, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  .mm-quali-logo {
    top: -60%;
    left: 30px;
    width: 900px;
  }
}

@keyframes redSquare {
  0% {
    transform: translateY(100%);
  }
  30% {
    transform: translateY(0%);
  }
  70% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes svgSquare {
  0% {
    transform: translateY(0%);
  }
  60% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* line 208, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-banner h2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  width: 45%;
  padding: 30px 8% 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
}

@media (max-width: 767px) {
  /* line 208, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  .mm-banner h2 {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 30px;
    margin-bottom: 100px;
  }
}

@media (max-width: 479px) {
  /* line 208, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  .mm-banner h2 {
    margin-bottom: 50px;
  }
}

/* line 234, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-banner h2 span {
  font-family: "futura-pt-bold";
  font-size: 70px;
  line-height: 85px;
  color: #0F1C2E;
  text-transform: uppercase;
  transform: translateY(200%);
  opacity: 0;
  transition: all 500ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 234, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
  .mm-banner h2 span {
    font-size: 50px;
    line-height: 65px;
  }
}

/* line 249, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-banner h2 span:nth-child(1) {
  transition-delay: 100ms;
}

/* line 253, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-banner h2 span:nth-child(2) {
  transition-delay: 300ms;
}

/* line 257, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-home-animation.scss */
.mm-banner h2 span:last-child {
  color: white;
  transition-delay: 500ms;
}

@keyframes sticky {
  0% {
    transform: translateY(-71px);
  }
  100% {
    transform: translateY(0);
  }
}

/* line 11, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 55%;
  margin: 0 0 0 auto;
  position: relative;
  z-index: 9999;
  padding: 40px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 767px) {
  /* line 11, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
  .c-header {
    max-width: 100%;
    background: white;
    padding: 20px 30px;
  }
}

/* line 33, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header.sticky {
  position: fixed;
  max-width: 100%;
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  z-index: 999999;
  animation: sticky 500ms ease-in-out;
  animation-direction: alternate;
  padding: 20px 40px;
}

/* line 43, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header.sticky .c-header__menu,
.c-header.sticky .c-header__logo {
  transform: translateY(0%) !important;
}

/* line 49, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__logo {
  transform: translateY(-300%);
  transition: all 500ms ease-in-out;
}

/* line 53, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__logo img {
  max-width: 200px;
  width: 100%;
  margin-top: 5px;
}

@media screen and (max-width: 1290px) {
  /* line 53, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
  .c-header__logo img {
    max-width: 170px;
  }
}

/* line 64, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  transform: translateY(-300%);
  transition: all 500ms ease-in-out;
}

@media screen and (max-width: 1150px) {
  /* line 64, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
  .c-header__menu {
    display: none;
  }
}

/* line 79, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__menu li {
  padding-left: 30px;
}

@media screen and (max-width: 1290px) {
  /* line 79, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
  .c-header__menu li {
    padding-left: 15px;
  }
}

/* line 86, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__menu li a {
  font-family: "futura-pt-bold";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0F1C2E;
  text-transform: uppercase;
  transition: color 300ms ease-in-out;
}

/* line 95, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
.c-header__menu li a:hover {
  color: #F94343;
}

@media screen and (max-width: 1290px) {
  /* line 86, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-header.scss */
  .c-header__menu li a {
    font-size: 11px;
  }
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-line-pattern.scss */
.c-line-pattern {
  background-image: url(../images/line-pattern.png);
  background-repeat: repeat;
  background-position: center;
  background-color: #f6f6f6;
}

/* line 2, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-section-title.scss */
.c-section-title h2 {
  font-family: "futura-pt-bold";
  font-weight: 900;
  font-size: 90px;
  line-height: 90px;
  color: #0F1C2E;
  text-transform: uppercase;
}

@media (max-width: 599px) {
  /* line 2, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-section-title.scss */
  .c-section-title h2 {
    font-size: 55px;
    line-height: 55px;
  }
}

@media (max-width: 479px) {
  /* line 2, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-section-title.scss */
  .c-section-title h2 {
    font-size: 45px;
    line-height: 45px;
  }
}

/* line 20, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-section-title.scss */
.c-section-title h2:after {
  content: '.';
  color: #F94343;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
.c-separator {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 479px) {
  /* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
  .c-separator {
    bottom: 0px;
  }
}

/* line 14, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
.c-separator img {
  max-width: 60px;
  backface-visibility: hidden;
}

/* line 19, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
.c-separator:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #F94343;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -20px;
  margin: 0 auto;
}

/* line 33, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
.c-separator:after {
  content: '';
  position: absolute;
  display: block;
  margin: 0 auto;
  top: 40px;
  left: 0px;
  right: 0px;
  height: 30px;
  width: 1px;
  background: #F94343;
}

/* line 48, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-separator.scss */
.wrapper--2 .c-separator {
  bottom: 0px;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs {
  background: #f6f6f6;
  overflow-x: hidden;
  position: relative;
  border-bottom: 1px solid #e9eaeb;
}

/* line 8, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9eaeb;
  border-bottom: 1px solid #e9eaeb;
}

@media (max-width: 767px) {
  /* line 8, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__menu {
    display: none;
  }
}

/* line 21, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item {
  width: calc(100% / 3);
  text-align: center;
  padding: 35px 20px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

@media (max-width: 599px) {
  /* line 21, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__item {
    display: none;
  }
}

/* line 39, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item.active:before {
  opacity: 0;
  height: 100%;
  transition-delay: 200ms;
}

/* line 45, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item.active:after {
  transform: translateY(0%);
}

/* line 49, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item.active span {
  color: white;
}

/* line 54, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item:before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #f6f6f6;
  transition: all 300ms ease-in-out;
  transform: translateX(0%);
  opacity: 1;
}

/* line 67, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: rgba(249, 67, 67, 0.9);
  margin: 0 auto;
  transform: translateY(100%);
  transition: all 300ms ease-in-out;
}

/* line 85, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs__item span {
  font-family: "futura-pt-bold";
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #0F1C2E;
  transition: all 300ms ease-in-out;
  position: relative;
  z-index: 9999;
}

@media (max-width: 767px) {
  /* line 85, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__item span {
    font-size: 15px;
  }
}

/* line 102, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes {
  position: relative;
  min-height: 610px;
}

/* line 111, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 767px) {
  /* line 111, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes .c-tabs__content {
    position: relative;
    flex-flow: column wrap;
  }
}

/* line 127, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__content.active .c-tabs__description {
  transform: translateX(0%);
  transition-delay: 500ms;
}

/* line 132, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__content.active .c-tabs__image {
  transform: translateX(0%);
  transition-delay: 500ms;
}

/* line 139, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .mw600 {
  max-width: 600px;
  margin: 0 auto;
}

/* line 144, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes h2 {
  font-family: "futura-pt-bold";
  font-size: 50px;
  line-height: 58px;
  color: #0F1C2E;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1024px) {
  /* line 144, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes h2 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 479px) {
  /* line 144, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes h2 {
    font-size: 25px;
    line-height: 33px;
  }
}

/* line 165, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description,
.c-tabs .c-tabs__panes .c-tabs__image {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  z-index: 9999;
  width: 50%;
}

@media (max-width: 767px) {
  /* line 165, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes .c-tabs__description,
  .c-tabs .c-tabs__panes .c-tabs__image {
    width: 100%;
  }
}

/* line 179, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description {
  padding: 40px 60px;
  transform: translateX(-150%);
  transition: all 500ms ease-in-out;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  /* line 179, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes .c-tabs__description {
    padding: 60px 30px;
  }
}

@media (max-width: 767px) {
  /* line 179, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes .c-tabs__description {
    transform: translateX(0%);
    order: 2;
  }
}

/* line 197, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description p {
  font-family: "abel";
  font-size: 15px;
  line-height: 24px;
  color: #7f8288;
  letter-spacing: 1px;
}

/* line 205, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description ul {
  list-style-type: none;
  padding-left: 0px;
}

/* line 209, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description ul li {
  font-family: "abel";
  font-size: 15px;
  line-height: 24px;
  color: #0F1C2E;
  letter-spacing: 1px;
}

/* line 215, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__description ul li:before {
  content: '•';
  color: #F94343;
  margin-right: 8px;
}

/* line 225, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__panes .c-tabs__image {
  background-size: cover !important;
  background-position: center !important;
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

@media (max-width: 767px) {
  /* line 225, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__panes .c-tabs__image {
    height: 300px;
    transform: translateX(0%);
  }
}

/* line 239, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__arrows {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

@media (max-width: 767px) {
  /* line 239, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
  .c-tabs .c-tabs__arrows {
    display: none;
  }
}

/* line 256, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__arrows div a {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: #0F1C2E;
  width: 60px;
  height: 50px;
  transition: all 300ms ease-in-out;
}

/* line 266, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__arrows div a:hover {
  background: #F94343;
}

/* line 270, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-tabs.scss */
.c-tabs .c-tabs__arrows div a img {
  max-width: 6px;
  backface-visibility: hidden;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs {
  flex: auto;
}

@media (max-width: 1024px) {
  /* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs {
    width: 100%;
  }
}

/* line 8, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul {
  list-style-type: none;
  padding-left: 0px;
  position: relative;
  z-index: 9999;
}

/* line 14, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) {
  /* line 14, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

/* line 25, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.label {
  padding: 30px 10px;
  border-bottom: 3px solid #0F1C2E;
}

@media (max-width: 1024px) {
  /* line 25, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.label {
    display: none;
  }
}

/* line 34, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.label span {
  font-family: "abel";
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #0F1C2E;
  box-sizing: border-box;
}

/* line 42, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.label span:nth-child(1) {
  width: 50%;
}

/* line 46, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.label span:nth-child(2) {
  width: 25%;
  padding-left: 10px;
  box-sizing: border-box;
}

/* line 52, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.label span:nth-child(3) {
  width: 25%;
  text-align: center;
}

@media (max-width: 1024px) {
  /* line 57, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.label span:nth-child(1), .c-jobs ul li.label span:nth-child(2), .c-jobs ul li.label span:nth-child(3) {
    width: 100%;
  }
}

/* line 68, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry {
  border-bottom: 1px solid #828991;
  padding: 6px 0px;
  position: relative;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 68, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry {
    padding: 30px 0px;
  }
}

/* line 78, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:last-child {
  border-bottom: 0px;
}

/* line 82, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  height: 1px;
  background: #d8d8d8;
  display: block;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 82, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry:before {
    display: none;
  }
}

/* line 99, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  height: 1px;
  background: #d8d8d8;
  display: block;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 99, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry:after {
    display: none;
  }
}

/* line 116, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover {
  background: #efeff0;
}

@media (max-width: 1024px) {
  /* line 116, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry:hover {
    background: transparent;
  }
}

/* line 123, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover a {
  background: #F94343;
  color: white;
}

/* line 127, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover a img {
  filter: brightness(0) invert(1);
}

/* line 132, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover:before {
  opacity: 1;
}

/* line 136, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover:after {
  opacity: 1;
}

/* line 140, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry:hover h3 {
  color: #F94343;
}

@media (max-width: 1024px) {
  /* line 140, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry:hover h3 {
    color: #0F1C2E;
  }
}

/* line 149, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry * {
  width: calc(100% / 3);
  box-sizing: border-box;
}

/* line 154, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry h3 {
  font-family: "futura-pt-bold";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0F1C2E;
  letter-spacing: 1px;
  width: 50%;
  margin: 0px;
  padding: 30px 10px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 154, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry h3 {
    width: 100%;
  }
  /* line 170, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry h3:before {
    content: attr(data-label);
    font-family: "abel";
    font-size: 15px;
    font-weight: 400;
    color: #0F1C2E;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
  }
}

/* line 183, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry span {
  font-family: "futura-pt-bold";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0F1C2E;
  letter-spacing: 1px;
  width: 25%;
  padding: 30px 10px;
}

@media (max-width: 1024px) {
  /* line 183, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry span {
    padding: 0px 10px 30px;
  }
  /* line 198, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry span:before {
    content: attr(data-label);
    font-family: "abel";
    font-size: 15px;
    font-weight: 400;
    color: #0F1C2E;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
  }
}

/* line 212, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry a {
  font-family: "futura-pt-bold";
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  text-transform: uppercase;
  color: #74787e;
  letter-spacing: 1px;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 30px 30px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 212, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry a {
    width: 100%;
    background: #F94343;
    color: white;
    padding: 12px 25px;
    max-width: 250px;
  }
}

/* line 237, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
.c-jobs ul li.c-jobs__entry a img {
  max-width: 8px;
  backface-visibility: hidden;
  margin-left: 10px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 1024px) {
  /* line 237, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-jobs.scss */
  .c-jobs ul li.c-jobs__entry a img {
    filter: brightness(0) invert(1);
  }
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-clients.scss */
.c-clients {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex: auto;
}

/* line 8, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-clients.scss */
.c-clients img {
  max-width: 150px;
  margin: 20px 30px;
}

/* line 12, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-clients.scss */
.c-clients img:first-child {
  max-width: 175px;
}

/* line 16, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-clients.scss */
.c-clients img:last-child {
  max-width: 125px;
}

/* line 3, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages {
  width: 100%;
  margin-top: 40px;
}

/* line 7, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages > div {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
}

/* line 12, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages > div h3 {
  font-family: "futura-pt-bold";
  font-size: 20px;
  line-height: 26px;
  color: #0F1C2E;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

/* line 22, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages > div h3 + span {
  font-family: "futura-pt-bold";
  font-size: 14px;
  line-height: 20px;
  color: #F94343;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
}

/* line 34, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages > div p {
  font-family: abel;
  font-size: 15px;
  line-height: 24px;
  color: #7f8288;
  letter-spacing: 1px;
}

/* line 43, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages .slick-dots {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/* line 51, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages .slick-dots li {
  margin: 0px 2px;
}

/* line 56, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages .slick-dots li.slick-active button {
  background: #F94343;
}

/* line 62, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages .slick-dots li button {
  margin: 0px 5px;
  display: inline-block;
  font-size: 0;
  background: #828991;
  border-radius: 100%;
  border: 0px;
  width: 15px;
  height: 15px;
  transition: all 300ms ease-in-out;
}

/* line 73, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.c-temoignages .js-temoignages .slick-dots li button:focus {
  outline: 0;
}

/* line 82, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.slick-slide {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

/* line 87, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-temoignages.scss */
.slick-slide.slick-active {
  opacity: 1;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer {
  background-image: url("../images/footer-logo-bg.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 120%;
  position: relative;
  background-color: #0F1C2E;
  padding: 100px 40px 40px;
}

/* line 10, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  /* line 10, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer__container {
    flex-flow: column wrap;
  }
}

/* line 23, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__address {
  padding-left: 40px;
}

@media (max-width: 1024px) {
  /* line 23, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer__address {
    padding-left: 0px;
  }
}

/* line 30, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__address > span {
  font-family: "abel";
  font-size: 15px;
  line-height: 22px;
  color: #F94343;
  margin-bottom: 20px;
  display: block;
}

/* line 39, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__address > div {
  font-family: "abel";
  font-size: 14px;
  line-height: 22px;
  color: #f6f6f6;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}

/* line 48, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__address > div a {
  font-family: "futura-pt-bold";
  color: white;
  font-weight: 900;
}

/* line 57, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-family: "futura-pt-bold";
  font-size: 12px;
  line-height: 16px;
  color: #f6f6f6;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

/* line 72, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright .right a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: 25px;
}

/* line 80, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright .right img {
  max-width: 25px;
  margin-left: 10px;
  transition: all 300ms ease-in-out;
}

/* line 88, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright .right a:hover img {
  filter: brightness(0%) invert(100%);
}

@media (max-width: 599px) {
  /* line 57, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer__copyright {
    flex-flow: column wrap;
    align-items: flex-start;
  }
  /* line 98, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer__copyright .left {
    margin-bottom: 10px;
  }
}

/* line 103, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright a {
  color: #f6f6f6;
  margin-left: 30px;
  font-size: 12px;
}

@media (max-width: 599px) {
  /* line 103, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer__copyright a {
    margin-left: 0px;
    margin-right: 30px;
  }
}

/* line 116, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer h2 {
  font-family: "futura-pt-bold";
  font-weight: 900;
  font-size: 90px;
  line-height: 90px;
  color: white;
  text-transform: uppercase;
  margin: 0px;
}

@media (max-width: 1024px) {
  /* line 116, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer h2 {
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 30px;
  }
}

@media (max-width: 479px) {
  /* line 116, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
  .c-footer h2 {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

/* line 137, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer h2:after {
  content: '.';
  color: #F94343;
}

/* line 143, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-footer.scss */
.c-footer h3 {
  font-family: "futura-pt-bold";
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
  margin: 0px 0px 5px;
  letter-spacing: 1px;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form {
  padding: 40px 0px;
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* line 7, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item {
  position: relative;
  margin-bottom: 40px;
}

/* line 11, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item .mm-form__input {
  position: relative;
  overflow: hidden;
}

/* line 15, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item .mm-form__input:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 1px;
  background: white;
  z-index: 9999;
  transition: all 500ms ease-in-out;
  transform: translateX(-100%);
}

/* line 31, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item.active .mm-form__placeholder {
  transform: translateY(-25px);
  transition-delay: 0ms;
  color: #F94343;
}

/* line 37, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item.active .mm-form__input:before {
  transform: translateX(0%);
}

/* line 42, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__item span.wpcf7-not-valid-tip {
  font-family: "abel";
  font-size: 13px;
  color: #F94343;
  position: absolute;
  bottom: -35px;
}

/* line 51, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-validation-errors,
.c-form div.wpcf7-mail-sent-ng,
.c-form div.wpcf7-mail-sent-ok {
  border: 0px;
  margin: 0px !important;
  padding: 0px 20px !important;
  font-family: "abel";
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* line 64, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-validation-errors,
.c-form div.wpcf7-mail-sent-ng {
  color: #F94343;
}

/* line 69, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-mail-sent-ok {
  color: #76b959;
}

/* line 73, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__placeholder {
  position: absolute;
  top: 4px;
  left: 20px;
  font-family: "futura-pt-bold";
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 300ms ease-in-out;
  transition-delay: 500ms;
  z-index: 0;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
}

@media (max-width: 1024px) {
  /* line 73, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__placeholder {
    left: 0px;
  }
}

/* line 94, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 1024px) {
  /* line 94, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__row {
    flex-flow: column wrap;
  }
}

/* line 104, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div {
  box-sizing: border-box;
  padding: 0px 20px;
}

@media (max-width: 1024px) {
  /* line 104, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__row > div {
    padding: 0px 0px;
  }
}

/* line 112, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input, .c-form .mm-form__row > div textarea {
  width: 100%;
  background: transparent;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #4c5158;
  padding: 4px 0px;
  color: #f6f6f6;
  opacity: 1;
  font-family: "abel";
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
}

/* line 127, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input:focus, .c-form .mm-form__row > div textarea:focus {
  outline: 0;
}

/* line 130, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input:focus::-webkit-input-placeholder, .c-form .mm-form__row > div textarea:focus::-webkit-input-placeholder {
  opacity: 1 !important;
}

/* line 135, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input:focus:-moz-placeholder, .c-form .mm-form__row > div textarea:focus:-moz-placeholder {
  opacity: 1 !important;
}

/* line 140, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input:focus::-moz-placeholder, .c-form .mm-form__row > div textarea:focus::-moz-placeholder {
  opacity: 1 !important;
}

/* line 145, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input:focus:-ms-input-placeholder, .c-form .mm-form__row > div textarea:focus:-ms-input-placeholder {
  opacity: 1 !important;
}

/* line 152, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input.wpcf7-submit, .c-form .mm-form__row > div textarea.wpcf7-submit {
  border-bottom: 0px;
  max-width: 120px;
  border: 1px solid #fff;
  height: 40px;
  font-family: "futura-pt-bold";
  font-size: 13px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

/* line 164, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input.wpcf7-submit:hover, .c-form .mm-form__row > div textarea.wpcf7-submit:hover {
  background: white;
  color: #0F1C2E;
}

/* line 169, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row > div input.wpcf7-submit + .ajax-loader, .c-form .mm-form__row > div textarea.wpcf7-submit + .ajax-loader {
  display: none;
}

/* line 178, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row.one > div {
  width: 33.3333%;
}

@media (max-width: 1024px) {
  /* line 178, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__row.one > div {
    width: 100%;
  }
}

/* line 189, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row.two >  div {
  margin-bottom: 20px;
}

/* line 193, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row.two > div:nth-child(1) {
  width: 66.6666%;
}

@media (max-width: 1024px) {
  /* line 193, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__row.two > div:nth-child(1) {
    width: 100%;
  }
}

/* line 201, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
.c-form .mm-form__row.two > div:nth-child(2) {
  width: 33.3333%;
}

@media (max-width: 1024px) {
  /* line 201, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-form.scss */
  .c-form .mm-form__row.two > div:nth-child(2) {
    width: 100%;
  }
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes {
  position: absolute;
  top: 140px;
  right: 0px;
  left: 0px;
  margin: auto;
  z-index: 9999;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  opacity: 0;
  transition: all 500ms ease-in-out;
  pointer-events: none;
}

@media (max-width: 1024px) {
  /* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes {
    top: 100px;
  }
}

/* line 21, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes.active {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: initial;
}

/* line 27, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__close {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #F94343;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

/* line 41, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__close:hover {
  background: #fa5757;
}

/* line 45, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__close img {
  max-width: 10px;
}

/* line 50, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper {
  width: 100%;
  height: 525px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 0px 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

@media (max-width: 1024px) {
  /* line 50, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 599px) {
  /* line 50, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper {
    height: 220px;
    box-sizing: border-box;
    padding: 0px 0px;
  }
}

/* line 75, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__text {
  max-width: 530px;
  width: 100%;
  padding: 60px;
  background: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  /* line 75, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper .c-panes__text {
    margin-bottom: 0px;
    margin-top: 220px;
  }
}

@media (max-width: 599px) {
  /* line 75, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper .c-panes__text {
    padding: 60px 40px;
  }
}

/* line 92, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__text h2 {
  font-family: "futura-pt-bold";
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0F1C2E;
}

/* line 100, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__text h2:after {
  content: '.';
  color: #F94343;
}

@media (max-width: 479px) {
  /* line 92, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper .c-panes__text h2 {
    font-size: 35px;
    line-height: 35px;
  }
}

/* line 111, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__text p {
  font-family: abel;
  font-size: 15px;
  line-height: 28px;
  color: #7f8288;
  letter-spacing: 1px;
  margin: 0px;
}

/* line 121, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__next {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
  background: white;
  color: #000;
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-family: "futura-pt-bold";
  font-size: 12px;
  line-height: 16px;
  color: #0F1C2E;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  /* line 121, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
  .c-panes__wrapper .c-panes__next {
    top: 0px;
    left: 0px;
    right: initial;
    bottom: initial;
  }
}

/* line 146, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-panes.scss */
.c-panes__wrapper .c-panes__next img {
  max-width: 8px;
  margin-left: 10px;
}

/* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.c-mobile__icon {
  width: 23px;
  height: auto;
  backface-visibility: hidden;
  transform: translateY(-300%);
  display: none;
  transition: transform 500ms ease-in-out;
}

@media screen and (max-width: 1150px) {
  /* line 1, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
  .c-mobile__icon {
    display: block;
  }
}

/* line 14, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.c-mobile-nav {
  position: fixed;
  top: 0px;
  left: 0px;
  transform: translate(-100%, 0px);
  background: #0F1C2E;
  width: 100%;
  max-width: 400px;
  height: 100%;
  transition: all 300ms ease-out;
  z-index: 9999999;
  padding: 30px;
  box-sizing: border-box;
}

/* line 28, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.c-mobile-nav.active {
  transform: translate(0%, 0px);
}

/* line 33, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav {
  overflow: hidden;
}

/* line 36, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav:before {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: rotate(45deg);
  opacity: 0.15;
  pointer-events: none;
  width: 150%;
  height: 150%;
  top: -100px;
}

/* line 51, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-logo img {
  max-width: 220px;
  height: auto;
  width: 100%;
  margin-bottom: 30px;
}

/* line 59, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-header {
  height: 100%;
}

/* line 62, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-header ul {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  list-style-type: none;
  padding-left: 0px;
}

/* line 73, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-header ul li a {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 700;
  display: block;
  font-family: "futura-pt-bold";
  text-transform: uppercase;
  color: white;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

/* line 85, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-header ul li a:hover {
  color: #F94343;
}

/* line 91, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-mobile-nav .c-mobile-header ul li.current-menu-item a:before {
  width: 20px;
}

/* line 99, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-menu-button.close {
  position: absolute;
  right: 30px;
  background: transparent;
  color: #F94343;
  border: 0px;
  font-size: 24px;
  transition: color 300ms ease-in-out;
  cursor: pointer;
}

/* line 109, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-menu-button.close:hover {
  color: #fa5c5c;
}

/* line 113, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.js-menu-button.close:focus {
  outline: 0;
}

/* line 118, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.mobile-nav-phone {
  margin-top: -40px;
  font-family: "futura-pt-bold";
  letter-spacing: 1px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}

/* line 128, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.mobile-nav-phone a {
  color: #F94343;
  margin-right: 5px;
  transition: color 300ms ease-in-out;
}

/* line 133, public/wp-content/themes/mmqualiservices/assets/src/scss/component/_c-mobile.scss */
.mobile-nav-phone a:hover {
  color: #fa5c5c;
}

/* =======================================================================
Pages
========================================================================== */
/* line 15, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper, .wrapper--2,
.wrapper--3 {
  padding: 0px 40px;
  background-image: url("../images/background-logo.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  position: relative;
  overflow: hidden;
}

@media (max-width: 599px) {
  /* line 15, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper, .wrapper--2,
  .wrapper--3 {
    padding: 0px 20px;
  }
}

/* line 27, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper--2 {
  background-image: none;
}

/* line 30, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper--2 .container, .wrapper--2 .container--col, .wrapper--2
.container--lowpad {
  flex-flow: row wrap !important;
}

@media (max-width: 1024px) {
  /* line 30, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper--2 .container, .wrapper--2 .container--col, .wrapper--2
  .container--lowpad {
    flex-flow: column wrap !important;
  }
}

/* line 38, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper--2 .wrapper__content, .wrapper--2 .wrapper__content--half {
  max-width: 450px !important;
  margin-top: 20px !important;
  flex: 1 1 100%;
}

/* line 44, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper--2:after {
  width: 600px !important;
  height: 600px !important;
  bottom: 110px !important;
  right: -160px !important;
}

/* line 52, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .circle-left, .wrapper--2 .circle-left,
.wrapper--3 .circle-left,
.wrapper .bottom-circle-left, .wrapper--2 .bottom-circle-left,
.wrapper--3 .bottom-circle-left {
  position: absolute;
  display: block;
  width: 500px;
  height: 500px;
  left: -100px;
  background: rgba(15, 28, 46, 0.03);
  pointer-events: none;
  border-radius: 100%;
}

/* line 64, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .circle-right, .wrapper--2 .circle-right,
.wrapper--3 .circle-right,
.wrapper .bottom-circle-right, .wrapper--2 .bottom-circle-right,
.wrapper--3 .bottom-circle-right {
  position: absolute;
  display: block;
  width: 500px;
  height: 500px;
  bottom: -200px;
  right: -100px;
  background: rgba(15, 28, 46, 0.03);
  pointer-events: none;
  border-radius: 100%;
}

/* line 77, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container, .wrapper--2 .container,
.wrapper--3 .container, .wrapper .container--col, .wrapper--2 .container--col,
.wrapper--3 .container--col, .wrapper
.container--lowpad, .wrapper--2
.container--lowpad,
.wrapper--3
.container--lowpad {
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding: 120px 0px;
}

@media (max-width: 1024px) {
  /* line 77, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container, .wrapper--2 .container,
  .wrapper--3 .container, .wrapper .container--col, .wrapper--2 .container--col,
  .wrapper--3 .container--col, .wrapper
  .container--lowpad, .wrapper--2
  .container--lowpad,
  .wrapper--3
  .container--lowpad {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

/* line 95, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container--col, .wrapper--2 .container--col,
.wrapper--3 .container--col {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 140px;
}

@media (max-width: 479px) {
  /* line 95, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container--col, .wrapper--2 .container--col,
  .wrapper--3 .container--col {
    margin-top: 50px;
  }
}

/* line 105, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container--col .wrapper__content, .wrapper--2 .container--col .wrapper__content,
.wrapper--3 .container--col .wrapper__content, .wrapper .container--col .wrapper__content--half, .wrapper--2 .container--col .wrapper__content--half,
.wrapper--3 .container--col .wrapper__content--half {
  margin-top: 0px !important;
}

/* line 110, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container--lowpad, .wrapper--2 .container--lowpad,
.wrapper--3 .container--lowpad {
  padding: 60px 0px 120px;
}

/* line 114, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .one-half, .wrapper--2 .container .one-half,
.wrapper--3 .container .one-half, .wrapper .container--col .one-half, .wrapper--2 .container--col .one-half,
.wrapper--3 .container--col .one-half, .wrapper
.container--lowpad .one-half, .wrapper--2
.container--lowpad .one-half,
.wrapper--3
.container--lowpad .one-half {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

/* line 121, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .one-half .wrapper__content--half, .wrapper--2 .container .one-half .wrapper__content--half,
.wrapper--3 .container .one-half .wrapper__content--half, .wrapper .container--col .one-half .wrapper__content--half, .wrapper--2 .container--col .one-half .wrapper__content--half,
.wrapper--3 .container--col .one-half .wrapper__content--half, .wrapper
.container--lowpad .one-half .wrapper__content--half, .wrapper--2
.container--lowpad .one-half .wrapper__content--half,
.wrapper--3
.container--lowpad .one-half .wrapper__content--half {
  max-width: 420px;
  box-sizing: border-box;
  padding: 0px 20px;
}

@media (max-width: 479px) {
  /* line 121, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container .one-half .wrapper__content--half, .wrapper--2 .container .one-half .wrapper__content--half,
  .wrapper--3 .container .one-half .wrapper__content--half, .wrapper .container--col .one-half .wrapper__content--half, .wrapper--2 .container--col .one-half .wrapper__content--half,
  .wrapper--3 .container--col .one-half .wrapper__content--half, .wrapper
  .container--lowpad .one-half .wrapper__content--half, .wrapper--2
  .container--lowpad .one-half .wrapper__content--half,
  .wrapper--3
  .container--lowpad .one-half .wrapper__content--half {
    padding: 0px;
  }
}

/* line 133, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .c-section-title, .wrapper--2 .container .c-section-title,
.wrapper--3 .container .c-section-title, .wrapper .container--col .c-section-title, .wrapper--2 .container--col .c-section-title,
.wrapper--3 .container--col .c-section-title, .wrapper
.container--lowpad .c-section-title, .wrapper--2
.container--lowpad .c-section-title,
.wrapper--3
.container--lowpad .c-section-title {
  width: 100%;
  max-width: 450px;
}

/* line 138, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content, .wrapper--2 .container .wrapper__content,
.wrapper--3 .container .wrapper__content, .wrapper .container--col .wrapper__content, .wrapper--2 .container--col .wrapper__content,
.wrapper--3 .container--col .wrapper__content, .wrapper
.container--lowpad .wrapper__content, .wrapper--2
.container--lowpad .wrapper__content,
.wrapper--3
.container--lowpad .wrapper__content, .wrapper .container .wrapper__content--half, .wrapper--2 .container .wrapper__content--half,
.wrapper--3 .container .wrapper__content--half, .wrapper .container--col .wrapper__content--half, .wrapper--2 .container--col .wrapper__content--half,
.wrapper--3 .container--col .wrapper__content--half, .wrapper
.container--lowpad .wrapper__content--half, .wrapper--2
.container--lowpad .wrapper__content--half,
.wrapper--3
.container--lowpad .wrapper__content--half {
  max-width: 550px;
  margin-top: 230px;
  padding-left: 15px;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 1024px) {
  /* line 138, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container .wrapper__content, .wrapper--2 .container .wrapper__content,
  .wrapper--3 .container .wrapper__content, .wrapper .container--col .wrapper__content, .wrapper--2 .container--col .wrapper__content,
  .wrapper--3 .container--col .wrapper__content, .wrapper
  .container--lowpad .wrapper__content, .wrapper--2
  .container--lowpad .wrapper__content,
  .wrapper--3
  .container--lowpad .wrapper__content, .wrapper .container .wrapper__content--half, .wrapper--2 .container .wrapper__content--half,
  .wrapper--3 .container .wrapper__content--half, .wrapper .container--col .wrapper__content--half, .wrapper--2 .container--col .wrapper__content--half,
  .wrapper--3 .container--col .wrapper__content--half, .wrapper
  .container--lowpad .wrapper__content--half, .wrapper--2
  .container--lowpad .wrapper__content--half,
  .wrapper--3
  .container--lowpad .wrapper__content--half {
    margin-top: 0px;
  }
}

@media (max-width: 479px) {
  /* line 138, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container .wrapper__content, .wrapper--2 .container .wrapper__content,
  .wrapper--3 .container .wrapper__content, .wrapper .container--col .wrapper__content, .wrapper--2 .container--col .wrapper__content,
  .wrapper--3 .container--col .wrapper__content, .wrapper
  .container--lowpad .wrapper__content, .wrapper--2
  .container--lowpad .wrapper__content,
  .wrapper--3
  .container--lowpad .wrapper__content, .wrapper .container .wrapper__content--half, .wrapper--2 .container .wrapper__content--half,
  .wrapper--3 .container .wrapper__content--half, .wrapper .container--col .wrapper__content--half, .wrapper--2 .container--col .wrapper__content--half,
  .wrapper--3 .container--col .wrapper__content--half, .wrapper
  .container--lowpad .wrapper__content--half, .wrapper--2
  .container--lowpad .wrapper__content--half,
  .wrapper--3
  .container--lowpad .wrapper__content--half {
    padding-left: 0px;
  }
}

/* line 153, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content h3, .wrapper--2 .container .wrapper__content h3,
.wrapper--3 .container .wrapper__content h3, .wrapper .container--col .wrapper__content h3, .wrapper--2 .container--col .wrapper__content h3,
.wrapper--3 .container--col .wrapper__content h3, .wrapper
.container--lowpad .wrapper__content h3, .wrapper--2
.container--lowpad .wrapper__content h3,
.wrapper--3
.container--lowpad .wrapper__content h3, .wrapper .container .wrapper__content--half h3, .wrapper--2 .container .wrapper__content--half h3,
.wrapper--3 .container .wrapper__content--half h3, .wrapper .container--col .wrapper__content--half h3, .wrapper--2 .container--col .wrapper__content--half h3,
.wrapper--3 .container--col .wrapper__content--half h3, .wrapper
.container--lowpad .wrapper__content--half h3, .wrapper--2
.container--lowpad .wrapper__content--half h3,
.wrapper--3
.container--lowpad .wrapper__content--half h3 {
  font-family: "futura-pt-bold";
  font-weight: 900;
  color: #0F1C2E;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

/* line 163, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content p, .wrapper--2 .container .wrapper__content p,
.wrapper--3 .container .wrapper__content p, .wrapper .container--col .wrapper__content p, .wrapper--2 .container--col .wrapper__content p,
.wrapper--3 .container--col .wrapper__content p, .wrapper
.container--lowpad .wrapper__content p, .wrapper--2
.container--lowpad .wrapper__content p,
.wrapper--3
.container--lowpad .wrapper__content p, .wrapper .container .wrapper__content--half p, .wrapper--2 .container .wrapper__content--half p,
.wrapper--3 .container .wrapper__content--half p, .wrapper .container--col .wrapper__content--half p, .wrapper--2 .container--col .wrapper__content--half p,
.wrapper--3 .container--col .wrapper__content--half p, .wrapper
.container--lowpad .wrapper__content--half p, .wrapper--2
.container--lowpad .wrapper__content--half p,
.wrapper--3
.container--lowpad .wrapper__content--half p {
  font-family: "abel";
  font-size: 15px;
  line-height: 24px;
  color: #7f8288;
  letter-spacing: 1px;
}

/* line 171, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content .wrapper__links, .wrapper--2 .container .wrapper__content .wrapper__links,
.wrapper--3 .container .wrapper__content .wrapper__links, .wrapper .container--col .wrapper__content .wrapper__links, .wrapper--2 .container--col .wrapper__content .wrapper__links,
.wrapper--3 .container--col .wrapper__content .wrapper__links, .wrapper
.container--lowpad .wrapper__content .wrapper__links, .wrapper--2
.container--lowpad .wrapper__content .wrapper__links,
.wrapper--3
.container--lowpad .wrapper__content .wrapper__links, .wrapper .container .wrapper__content--half .wrapper__links, .wrapper--2 .container .wrapper__content--half .wrapper__links,
.wrapper--3 .container .wrapper__content--half .wrapper__links, .wrapper .container--col .wrapper__content--half .wrapper__links, .wrapper--2 .container--col .wrapper__content--half .wrapper__links,
.wrapper--3 .container--col .wrapper__content--half .wrapper__links, .wrapper
.container--lowpad .wrapper__content--half .wrapper__links, .wrapper--2
.container--lowpad .wrapper__content--half .wrapper__links,
.wrapper--3
.container--lowpad .wrapper__content--half .wrapper__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

@media (max-width: 479px) {
  /* line 171, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container .wrapper__content .wrapper__links, .wrapper--2 .container .wrapper__content .wrapper__links,
  .wrapper--3 .container .wrapper__content .wrapper__links, .wrapper .container--col .wrapper__content .wrapper__links, .wrapper--2 .container--col .wrapper__content .wrapper__links,
  .wrapper--3 .container--col .wrapper__content .wrapper__links, .wrapper
  .container--lowpad .wrapper__content .wrapper__links, .wrapper--2
  .container--lowpad .wrapper__content .wrapper__links,
  .wrapper--3
  .container--lowpad .wrapper__content .wrapper__links, .wrapper .container .wrapper__content--half .wrapper__links, .wrapper--2 .container .wrapper__content--half .wrapper__links,
  .wrapper--3 .container .wrapper__content--half .wrapper__links, .wrapper .container--col .wrapper__content--half .wrapper__links, .wrapper--2 .container--col .wrapper__content--half .wrapper__links,
  .wrapper--3 .container--col .wrapper__content--half .wrapper__links, .wrapper
  .container--lowpad .wrapper__content--half .wrapper__links, .wrapper--2
  .container--lowpad .wrapper__content--half .wrapper__links,
  .wrapper--3
  .container--lowpad .wrapper__content--half .wrapper__links {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

/* line 184, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content a, .wrapper--2 .container .wrapper__content a,
.wrapper--3 .container .wrapper__content a, .wrapper .container--col .wrapper__content a, .wrapper--2 .container--col .wrapper__content a,
.wrapper--3 .container--col .wrapper__content a, .wrapper
.container--lowpad .wrapper__content a, .wrapper--2
.container--lowpad .wrapper__content a,
.wrapper--3
.container--lowpad .wrapper__content a, .wrapper .container .wrapper__content--half a, .wrapper--2 .container .wrapper__content--half a,
.wrapper--3 .container .wrapper__content--half a, .wrapper .container--col .wrapper__content--half a, .wrapper--2 .container--col .wrapper__content--half a,
.wrapper--3 .container--col .wrapper__content--half a, .wrapper
.container--lowpad .wrapper__content--half a, .wrapper--2
.container--lowpad .wrapper__content--half a,
.wrapper--3
.container--lowpad .wrapper__content--half a {
  font-family: "futura-pt-bold";
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  color: #0F1C2E;
  text-transform: uppercase;
  margin-right: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

@media (max-width: 479px) {
  /* line 184, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
  .wrapper .container .wrapper__content a, .wrapper--2 .container .wrapper__content a,
  .wrapper--3 .container .wrapper__content a, .wrapper .container--col .wrapper__content a, .wrapper--2 .container--col .wrapper__content a,
  .wrapper--3 .container--col .wrapper__content a, .wrapper
  .container--lowpad .wrapper__content a, .wrapper--2
  .container--lowpad .wrapper__content a,
  .wrapper--3
  .container--lowpad .wrapper__content a, .wrapper .container .wrapper__content--half a, .wrapper--2 .container .wrapper__content--half a,
  .wrapper--3 .container .wrapper__content--half a, .wrapper .container--col .wrapper__content--half a, .wrapper--2 .container--col .wrapper__content--half a,
  .wrapper--3 .container--col .wrapper__content--half a, .wrapper
  .container--lowpad .wrapper__content--half a, .wrapper--2
  .container--lowpad .wrapper__content--half a,
  .wrapper--3
  .container--lowpad .wrapper__content--half a {
    margin-bottom: 20px;
  }
}

/* line 202, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content a:before, .wrapper--2 .container .wrapper__content a:before,
.wrapper--3 .container .wrapper__content a:before, .wrapper .container--col .wrapper__content a:before, .wrapper--2 .container--col .wrapper__content a:before,
.wrapper--3 .container--col .wrapper__content a:before, .wrapper
.container--lowpad .wrapper__content a:before, .wrapper--2
.container--lowpad .wrapper__content a:before,
.wrapper--3
.container--lowpad .wrapper__content a:before, .wrapper .container .wrapper__content--half a:before, .wrapper--2 .container .wrapper__content--half a:before,
.wrapper--3 .container .wrapper__content--half a:before, .wrapper .container--col .wrapper__content--half a:before, .wrapper--2 .container--col .wrapper__content--half a:before,
.wrapper--3 .container--col .wrapper__content--half a:before, .wrapper
.container--lowpad .wrapper__content--half a:before, .wrapper--2
.container--lowpad .wrapper__content--half a:before,
.wrapper--3
.container--lowpad .wrapper__content--half a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0%;
  height: 2px;
  background: #F94343;
  transition: all 300ms ease-in-out;
}

/* line 213, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content a img, .wrapper--2 .container .wrapper__content a img,
.wrapper--3 .container .wrapper__content a img, .wrapper .container--col .wrapper__content a img, .wrapper--2 .container--col .wrapper__content a img,
.wrapper--3 .container--col .wrapper__content a img, .wrapper
.container--lowpad .wrapper__content a img, .wrapper--2
.container--lowpad .wrapper__content a img,
.wrapper--3
.container--lowpad .wrapper__content a img, .wrapper .container .wrapper__content--half a img, .wrapper--2 .container .wrapper__content--half a img,
.wrapper--3 .container .wrapper__content--half a img, .wrapper .container--col .wrapper__content--half a img, .wrapper--2 .container--col .wrapper__content--half a img,
.wrapper--3 .container--col .wrapper__content--half a img, .wrapper
.container--lowpad .wrapper__content--half a img, .wrapper--2
.container--lowpad .wrapper__content--half a img,
.wrapper--3
.container--lowpad .wrapper__content--half a img {
  max-width: 12px;
  margin-left: 15px;
  transition: all 300ms ease-in-out;
  filter: grayscale(1);
}

/* line 222, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content a:hover:before, .wrapper--2 .container .wrapper__content a:hover:before,
.wrapper--3 .container .wrapper__content a:hover:before, .wrapper .container--col .wrapper__content a:hover:before, .wrapper--2 .container--col .wrapper__content a:hover:before,
.wrapper--3 .container--col .wrapper__content a:hover:before, .wrapper
.container--lowpad .wrapper__content a:hover:before, .wrapper--2
.container--lowpad .wrapper__content a:hover:before,
.wrapper--3
.container--lowpad .wrapper__content a:hover:before, .wrapper .container .wrapper__content--half a:hover:before, .wrapper--2 .container .wrapper__content--half a:hover:before,
.wrapper--3 .container .wrapper__content--half a:hover:before, .wrapper .container--col .wrapper__content--half a:hover:before, .wrapper--2 .container--col .wrapper__content--half a:hover:before,
.wrapper--3 .container--col .wrapper__content--half a:hover:before, .wrapper
.container--lowpad .wrapper__content--half a:hover:before, .wrapper--2
.container--lowpad .wrapper__content--half a:hover:before,
.wrapper--3
.container--lowpad .wrapper__content--half a:hover:before {
  width: 100%;
}

/* line 226, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper .container .wrapper__content a:hover img, .wrapper--2 .container .wrapper__content a:hover img,
.wrapper--3 .container .wrapper__content a:hover img, .wrapper .container--col .wrapper__content a:hover img, .wrapper--2 .container--col .wrapper__content a:hover img,
.wrapper--3 .container--col .wrapper__content a:hover img, .wrapper
.container--lowpad .wrapper__content a:hover img, .wrapper--2
.container--lowpad .wrapper__content a:hover img,
.wrapper--3
.container--lowpad .wrapper__content a:hover img, .wrapper .container .wrapper__content--half a:hover img, .wrapper--2 .container .wrapper__content--half a:hover img,
.wrapper--3 .container .wrapper__content--half a:hover img, .wrapper .container--col .wrapper__content--half a:hover img, .wrapper--2 .container--col .wrapper__content--half a:hover img,
.wrapper--3 .container--col .wrapper__content--half a:hover img, .wrapper
.container--lowpad .wrapper__content--half a:hover img, .wrapper--2
.container--lowpad .wrapper__content--half a:hover img,
.wrapper--3
.container--lowpad .wrapper__content--half a:hover img {
  margin-left: 25px;
  filter: grayscale(0);
}

/* line 236, public/wp-content/themes/mmqualiservices/assets/src/scss/pages/home.scss */
.wrapper h2, .wrapper--2 h2,
.wrapper--3 h2 {
  margin-top: 0px;
}
