/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 599px),
	'medium': 		(max-width: 767px),
	'large': 		(max-width: 1024px),
	'xxl': 			(min-width: 1200px)
);


/* =======================================================================
Font-face
========================================================================== */

@font-face {
  font-family: 'abel';
  src: url('../fonts/abel/abel-regular.ttf') format("truetype");
}

/* =======================================================================
Variables
========================================================================== */

// Fonts stacks
$font-stack:              Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              #ff0000;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);
$abel:					  'abel';
$futura:				  'futura-pt-bold';

// Highlight
$color-selection:         #2269f6;
$color-placeholder:       #f6f6f6;

// Colors
$red:					  #F94343;
$green:					  #76b959;
$blue:					  #0F1C2E;
$black-opacity:			  #0f1c2d;
$light-grey:			  #f6f6f6;
$medium-grey:			  #7f8288;
$jobs-link-grey:		  #74787e;
$jobs-border-grey:		  #d8d8d8;
$border-grey:			  #828991;
$tabs-border:			  #e9eaeb;
