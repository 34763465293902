.c-mobile__icon {
    width: 23px;
    height: auto;
    backface-visibility: hidden;
	transform: translateY(-300%);
	display:none;
	transition:transform 500ms ease-in-out;

	@media screen and (max-width:1150px) {
		display:block;
	}
}

.c-mobile-nav {
		position: fixed;
		top: 0px;
		left: 0px;
		transform: translate(-100%, 0px);
		background: $blue;
		width: 100%;
		max-width: 400px;
		height: 100%;
		transition: all 300ms ease-out;
		z-index: 9999999;
		padding:30px;
		box-sizing:border-box;
		
		&.active {
			transform: translate(0%, 0px);
		}
	}

	.js-mobile-nav {
		overflow:hidden;

		&:before {
			display:block;
			height:100%;
			width:100%;
			position:absolute;
			transform:rotate(45deg);
			opacity: 0.15;
			pointer-events: none;
			width:150%;
			height:150%;
			top:-100px;
		}

		.c-mobile-logo {

			img {
				max-width:220px;
				height: auto;
				width:100%;
				margin-bottom:30px;
			}
		}

		.c-mobile-header {
			height:100%;

			ul {
				display:flex;
				flex-flow:column wrap;
				justify-content: center;
				align-items: flex-start;
				height: 100%;
				list-style-type: none;
				padding-left:0px;

				li {

					a {
						font-size:20px;
						line-height: 26px;
						margin-bottom:20px;
						font-weight: 700;
						display: block;
						font-family:$futura;
						text-transform:uppercase;
						color:white;
						transition:all 300ms ease-in-out;
						cursor: pointer;

						&:hover {
							color:$red;
						}
					}


					&.current-menu-item a:before {
						width:20px;
					}
				}
			}
		}
	}

	.js-menu-button.close {
	    position: absolute;
	    right: 30px;
	    background: transparent;
	    color: $red;
	    border:0px;
	    font-size:24px;
	    transition:color 300ms ease-in-out;
	    cursor:pointer;

	    &:hover {
	    	color:lighten($red, 5%);
	    }

	    &:focus {
			outline:0;
	    }
	}

	.mobile-nav-phone {
		margin-top:-40px;
		font-family:$futura;
		letter-spacing:1px;
		font-weight:bold;
		color:white;
		text-transform:uppercase;
		font-size:13px;
		-webkit-font-smoothing: antialiased;

		a {
			color:$red;
			margin-right:5px;
			transition:color 300ms ease-in-out;

			&:hover {
				color:lighten($red, 5%);
			}
		}
	}