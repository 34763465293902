.c-section-title {
	h2 {
		font-family: $futura;
		font-weight:900;
		font-size: 90px;
		line-height: 90px;
		color:$blue;
		text-transform:uppercase;

		@include mq('small') {
			font-size:55px;
			line-height: 55px;
		}

		@include mq('xsmall') {
			font-size:45px;
			line-height: 45px;
		}

		&:after {
			content:'.';
			color:$red;
		}
	}
}