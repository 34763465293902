.container--col,
.container--lowpad {
	@extend .container;
}

.wrapper__content--half {
	@extend .wrapper__content;
}

.wrapper--2,
.wrapper--3 {
	@extend .wrapper;
}

.wrapper {
	padding:0px 40px;
	background-image:url('../images/background-logo.svg');
	background-repeat:no-repeat;
	background-position-x:center;
	position:relative;
	overflow:hidden;

	@include mq('small') {
		padding:0px 20px;
	}

	&--2 {
		background-image:none;

		.container {
			flex-flow:row wrap !important;

			@include mq('large') {
				flex-flow:column wrap !important;
			}
		}

		 .wrapper__content  {
		 	max-width:450px !important;
		 	margin-top: 20px !important;
		 	flex: 1 1 100%;
		 }

		 &:after {
			width: 600px !important;
			height: 600px !important;
			bottom: 110px !important;
			right: -160px !important;
		 }
	}

	.circle-left,
	.bottom-circle-left {
		position:absolute;
		display: block;
		width:500px;
		height:500px;
		left:-100px;
		background:rgba($blue, 0.03);
		pointer-events: none;
		border-radius:100%;
	}

	.circle-right,
	.bottom-circle-right {
		position:absolute;
		display: block;
		width:500px;
		height:500px;
		bottom:-200px;
		right:-100px;
		background:rgba($blue, 0.03);
		pointer-events: none;
		border-radius:100%;
	}
	
	.container {
		max-width:1180px;
		margin:0 auto;
		width:100%;
		display: flex;
	    flex-flow: row nowrap;
	    justify-content: space-between;
	    align-items: center;
	    box-sizing: border-box;
	    position:relative;
	    padding:120px 0px;

	    @include mq('large') {
	    	flex-flow:column wrap;
	    	align-items:flex-start;
	    }


	    &--col {
	    	flex-flow:column wrap;
	    	justify-content: flex-start;
	    	align-items: flex-start;
	    	margin-top:140px;

	    	@include mq(xsmall) {
	    		margin-top:50px;
	    	}

	    	.wrapper__content  {
	    		margin-top:0px !important;
	    	}
	    }

	    &--lowpad {
	    	padding:60px 0px 120px;
	    }

	    .one-half {
	    	display:flex;
	    	flex-flow:row wrap;
	    	justify-content: center;
	    	align-items:flex-start;
	    	width:100%;
			
			.wrapper__content--half {
				max-width:420px;
				box-sizing:border-box;
				padding:0px 20px;

				@include mq('xsmall') {
					padding:0px;
				}
			}

	    }

	    .c-section-title {
	    	width:100%;
	    	max-width:450px;
	    }

	    .wrapper__content {
	    	max-width:550px;
	    	margin-top:230px;
    	    padding-left: 15px;
  			box-sizing: border-box;
  			position:relative;

  			@include mq('large') {
  				margin-top:0px;
  			}

  			@include mq('xsmall') {
				padding-left:0px;
  			}

	    	h3 {
	    		font-family: $futura;
	    		font-weight:900;
	    		color:$blue;
	    		font-size:15px;
	    		line-height: 20px;
	    		text-transform: uppercase;
	    		margin-bottom:40px;
	    	}

	    	p {
	    		font-family: $abel;
	    		font-size:15px;
	    		line-height: 24px;
	    		color:$medium-grey;
	    		letter-spacing: 1px;
	    	}

	    	.wrapper__links {
				display:flex;
				flex-flow:row wrap;
				justify-content: flex-start;
				align-items:center;
				margin-top:40px;

				@include mq('xsmall') {
					flex-flow:column wrap;
					align-items:flex-start;
				}
	    	}

	    	a {
				font-family: $futura;
				font-weight:900;
				font-size:13px;
				line-height: 18px;
				color:$blue;
				text-transform:uppercase;
				margin-right:40px;
				display: flex;
			    flex-flow: row wrap;
			    justify-content: flex-start;
			    align-items: center;
			    position:relative;

				@include mq('xsmall') {
					margin-bottom:20px;
				}

			    &:before {
			    	content:'';
			    	position:absolute;
			    	left:0;
			    	bottom:-10px;
			    	width:0%;
			    	height:2px;
			    	background: $red;
			    	transition:all 300ms ease-in-out;
			    }

	    		img {
	    			max-width:12px;
	    			margin-left:15px;
	    			transition:all 300ms ease-in-out;
	    			filter:grayscale(1);
	    		}

	    		&:hover {

	    			&:before {
	    				width:100%;
	    			}
				
	    			img {
	    				margin-left:25px;
	    				filter:grayscale(0);
	    			}

	    		}
	    	}
	    }
	}

	h2 {
		margin-top:0px;
	}
}