.c-tabs {
	background:$light-grey;
	overflow-x:hidden;
	position:relative;
	border-bottom: 1px solid $tabs-border;


	&__menu {
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items: center;
		border-top:1px solid $tabs-border;
		border-bottom:1px solid $tabs-border;

		@include mq('medium') {
			display:none;
		}
	}

	&__item {
		width:calc(100% / 3);
		text-align:center;
		padding:35px 20px;
		box-sizing:border-box;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		position:relative;
		overflow:hidden;
		cursor:pointer;

		@include mq('small') {
			display:none;
		}

		&.active {

			&:before {
				opacity: 0;
				height:100%;
				transition-delay: 200ms;
			}

			&:after {
				transform:translateY(0%);
			}

			span {
				color:white;
			}
		}

		&:before {
			content:'';
			position:absolute;
			top:0px;
			right:0px;
			bottom:0px;
			left:0px;
			background:$light-grey;
			transition:all 300ms ease-in-out;
			transform:translateX(0%);
			opacity: 1;
		}

		&:after {
			content:'';
			display:block;
			position:absolute;
			bottom:0px;
			left:0px;
			right:0px;
			top:0px;
			background:rgba($red, 0.9);
			margin:0 auto;
			transform:translateY(100%);
			transition:all 300ms ease-in-out;
		}

		&:hover {

		}

		span {
			font-family:$futura;
			font-weight:900;
			font-size:18px;
			line-height: 26px;
			text-transform: uppercase;
			color:$blue;
			transition:all 300ms ease-in-out;
			position:relative;
			z-index: 9999;

			@include mq('medium') {
				font-size:15px;
			}
		}
	}

	.c-tabs__panes {

		position:relative;
		min-height:610px;

		@include mq('medium') {

		}

		.c-tabs__content {
			display:flex;
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			margin:auto;

			@include mq('medium') {
				position:relative;
				flex-flow:column wrap;
			}

			&.active {

				.c-tabs__description {
					transform:translateX(0%);
					transition-delay:500ms;
				}

				.c-tabs__image {
					transform:translateX(0%);
					transition-delay:500ms;
				}
			}
		}

		.mw600 {
			max-width:600px;
			margin:0 auto;
		}
		
		h2 {
			font-family:$futura;
			font-size:50px;
			line-height: 58px;
			color:$blue;
			text-transform: uppercase;
			margin-top:0px;
			margin-bottom:30px;

			@include mq('large') {
				font-size:30px;
				line-height: 38px;
			}

			@include mq('xsmall') {
				font-size:25px;
				line-height: 33px;
			}

		}

		.c-tabs__description,
		.c-tabs__image {
			box-sizing:border-box;
			display:inline-block;
			position:relative;
			z-index: 9999;
			width:50%;

			@include mq('medium') {
				width:100%;
			}

		}

		.c-tabs__description {
			padding:40px 60px;
			transform:translateX(-150%);
			transition:all 500ms ease-in-out;
			display: flex;
		    flex-flow: column wrap;
		    justify-content: center;
		    align-items: flex-start;

		    @include mq('large') {
		    	padding:60px 30px;
		    }

		    @include mq('medium') {
		    	transform:translateX(0%);
		    	order:2;
		    }

			p {
	    		font-family: $abel;
	    		font-size:15px;
	    		line-height: 24px;
	    		color:$medium-grey;
	    		letter-spacing: 1px;
			}

			ul {
				list-style-type:none;
				padding-left:0px;

				li {
		    		font-family: $abel;
		    		font-size:15px;
		    		line-height: 24px;
		    		color:$blue;;
		    		letter-spacing: 1px;
		    		&:before {
		    			content:'•';
		    			color:$red;
		    			margin-right:8px;
		    		}
				}
			}

		}

		.c-tabs__image  {
			background-size:cover !important;
			background-position:center !important;
			transform:translateX(100%);
			transition:all 500ms ease-in-out;

			@include mq('medium') {
				height:300px;
				transform:translateX(0%);
			}

		}
	}

	.c-tabs__arrows {
		position:absolute;
		bottom:0px;
		left:0px;
		right:0px;
		display: flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items: center;
		z-index: 99999;

		@include mq('medium') {
			display:none;
		}

		div {

			a {
				display: flex;
				flex-flow:row wrap;
				justify-content: center;
				align-items: center;
				background:$blue;
				width:60px;
				height:50px;
				transition:all 300ms ease-in-out;

				&:hover {
					background:$red;
				}

				img {
					max-width:6px;
					backface-visibility: hidden;
				}
			}
		}
	}
}